

.header {
    background-color: #FFFFFF;
    /* display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(4px + 2vmin);
    color: white; */
  }

  #logo { width: 250px; height: 25px; }